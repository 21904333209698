import { extendTheme, ChakraTheme } from '@chakra-ui/react';
import colors from './_colors';
import global from './_global';
import components from './_components';

const customTheme: ChakraTheme = extendTheme({
  config: {
    useSystemColorMode: false,
    initialColorMode: 'light'
  },
  components: { ...components },
  textStyles: {
    h1: {
      fontSize: ['3xl', '4xl', '5xl'],
      fontWeight: 600,
      lineHeight: '1.2'
    },
    h2: {
      fontSize: ['2xl', '3xl', '4xl'],
      fontWeight: 600,
      lineHeight: '1.4'
    },
    h3: {
      fontSize: ['xl', '2xl', '3xl'],
      fontWeight: 600,
      lineHeight: '1.4'
    },
    h4: {
      fontSize: ['lg', 'xl', '2xl'],
      fontWeight: 600,
      lineHeight: '1.4'
    }
  },
  styles: {
    ...global
  },
  colors: {
    ...colors
  },
  fonts: {
    body: "'DMSans', sans-serif",
    heading: "'MADE TOMMY', sans-serif",
    mono: 'monospace'
  },
  fontSizes: {
    xs: '0.75rem', // 12px
    sm: '0.875rem', // 14px
    md: '1rem', // 16px
    lg: '1.125rem', // 18px
    xl: '1.25rem', //  20px
    '2xl': '1.5rem', // 24px
    '3xl': '1.875rem', // 30px
    '4xl': '2.25rem', // 36px
    '5xl': '3rem', // 48px
    '6xl': '3.75rem', // 60px
    '7xl': '4.5rem', // 72px
    '8xl': '6rem', // 96px
    '9xl': '8rem' // 128px
  },
  fontWeights: {
    hairline: 100,
    thin: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
    black: 900
  },
  lineHeights: {
    normal: 'normal',
    none: 1,
    shorter: 1.25,
    short: 1.375,
    base: 1.5,
    tall: 1.625,
    taller: '2',
    3: '.75rem',
    4: '1rem',
    5: '1.25rem',
    6: '1.5rem',
    7: '1.75rem',
    8: '2rem',
    9: '2.25rem',
    10: '2.5rem'
  },
  letterSpacings: {
    tighter: '-0.05em',
    tight: '-0.025em',
    normal: '0',
    wide: '0.025em',
    wider: '0.05em',
    widest: '0.1em'
  },
  space: {
    0: '0', // 0px
    1: '0.125rem', // 2px
    2: '0.25rem', // 4px
    3: '0.5rem', // 8px
    4: '1rem', // 16px
    5: '1.5rem', // 24px
    6: '2rem', // 32px
    8: '2.5rem', // 40px
    10: '3rem', // 48px
    11: '3.5rem', // 56px
    12: '4rem', // 64px
    14: '5rem', // 80px
    16: '6rem', // 96px
    20: '8rem', // 128px
    24: '11rem', // 176px
    32: '16rem' // 256px
  }
});

export default customTheme;
