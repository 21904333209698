import { mode } from '@chakra-ui/theme-tools';

const components = {
  Button: {
    baseStyle: {
      fontWeight: 600,
      _focus: {
        boxShadow: 'none'
      },
      _disabled: {
        opacity: 0.4,
        cursor: 'not-allowed',
        boxShadow: 'none'
      }
    },
    defaultProps: {
      colorScheme: 'blue'
    },
    sizes: {
      md: {
        fontSize: '15px'
      }
    }
  },
  Heading: {
    baseStyle: {
      fontWeight: 600,
      color: '#170F24'
    }
  },
  Select: {
    baseStyle: {
      field: {
        fontWeight: 600
      },
      icon: {
        fontSize: '20px'
      }
    },
    sizes: {
      md: {
        field: {
          fontSize: '15px',
          lineHeight: '16px',
          height: '42px'
        }
      }
    }
  },
  Text: {
    baseStyle: {
      fontSize: '16px',
      lineHeight: '1.6',
      fontFamily: 'body'
    }
  },
  Menu: {
    parts: ['item', 'list'],
    baseStyle: props => ({
      item: {
        py: '0.4rem',
        px: '0.8rem',
        transition: 'background 50ms ease-in 0s',
        borderRadius: 'md',
        _focus: {
          bg: mode(`gray.100`, `whiteAlpha.100`)(props)
        },
        _active: {
          bg: mode(`gray.200`, `whiteAlpha.200`)(props)
        },
        _expanded: {
          bg: mode(`gray.100`, `whiteAlpha.100`)(props)
        },
        _disabled: {
          opacity: 0.4,
          cursor: 'not-allowed'
        }
      },
      list: {
        bg: mode(`#fff`, `gray.700`)(props),
        boxShadow: mode(`sm`, `dark-lg`)(props),
        color: 'inherit',
        minW: '3xs',
        p: '2',
        zIndex: 1,
        borderRadius: 'md',
        borderWidth: '1px'
      }
    })
  }
};

export default components;
