const colors = {
  transparent: 'transparent',
  black: '#000',
  bg: '#fff',
  footerBg: '#2B263A',
  border: '#E8EEF3',
  shadow: 'rgba(0, 0, 0, 0.06)',
  text: '#2B263A',

  blackAlpha: {
    50: 'rgba(0, 0, 0, 0.04)',
    100: 'rgba(0, 0, 0, 0.06)',
    200: 'rgba(0, 0, 0, 0.08)',
    300: 'rgba(0, 0, 0, 0.16)',
    400: 'rgba(0, 0, 0, 0.24)',
    500: 'rgba(0, 0, 0, 0.36)',
    600: 'rgba(0, 0, 0, 0.48)',
    700: 'rgba(0, 0, 0, 0.64)',
    800: 'rgba(0, 0, 0, 0.80)',
    900: 'rgba(0, 0, 0, 0.92)'
  }
};

export default colors;
