const global = {
  global: () => ({
    'html, body': {
      scrollBehavior: 'smooth',
      backgroundColor: 'white',
      color: '#2B263A',
      webkitFontSmoothing: 'antialiased',
      fontSmoothing: 'always',
      fontFamily: 'DMSans, sans-serif'
    }
  })
};

export default global;
